import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import React, { useContext,useState, useEffect  } from 'react';

// @mui
import { Card, CardHeader, Box, IconButton } from '@mui/material';
// components
import { useChart } from '../../../components/chart';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};
const darkModeStyles = {
  background: '#111', // Dark background color
  color: '#fff', // Light text color
};
export default function AppWebsiteVisits({ title, subheader,datetime, chartLabels, chartData, displayStance, displayRelevance, height, limitStance, ...other }) {
  const [AxisLimit, setAxisLimit] = useState(false);

  const open = () => {    
    setAxisLimit(!AxisLimit);
  }
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '50%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: {
      type: datetime ? 'datetime' : '',
      labels: {
        style: {
          colors: '#eee', 
        },
      },
    },
    legend: {
      labels: {
        colors: '#fff', 
      },
    },
    yaxis: [
      displayStance
        ? {
            seriesName: 'Distanz',
            title: {
              text: 'Distanz',
            },
            min: 0,
            forceNiceScale: true,
            labels: {
              style: {
                colors: '#eee', // Set y-axis label color to white
              },
              formatter: (value) => value.toFixed(0),
            },
            tooltip: {
              enabled: true,
            },
          }
        : null,
      displayRelevance
        ? {
            opposite: true,
            seriesName: 'Relevance',
            forceNiceScale: true,
            labels: {
              style: {
                colors: '#eee', // Set y-axis label color to white
              },
              formatter: (value) =>
                value > 1 ? parseInt(value, 10) : value.toFixed(0),
            },
            tooltip: {
              enabled: true,
            },
          }
        : null,
    ].filter(Boolean),
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(2)}`;
          }
          return y;
        },
      },
    },
  });
  
  return (
    <Card style={{ height: "100%", ...darkModeStyles }} {...other}>
      
      <CardHeader title={title} subheader={subheader} style={{ color: '#fff' }} />

      <Box sx={{ p: 1, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={height} />
      </Box>
    </Card>
  );
}
