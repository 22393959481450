import { Helmet } from 'react-helmet-async';
// @mui
import { useState ,useEffect, useRef } from 'react';

import { Grid, Button, Container, Stack, Typography, Card } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// components
import Iconify from '../components/iconify';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../sections/@dashboard/blog';
// mock
import POSTS from '../_mock/blog';
import {
  AppDay,
  Upload
} from '../sections/@dashboard/app';
import { fShortenNumber } from '../utils/formatNumber';
// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function Highlights() {
  const [videos, setVideos] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  
  const [uploadResult, setUploadResult] = useState(null);
  
  async function fetchData(url) {
    const token = localStorage.getItem('token');
    console.log(token);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    
    let  data = await response.json();
    try {
      data = JSON.parse(data);    
    } catch (error) {
      console.log("err")
    }
  
    return data;
  }
  useEffect(() => {  
    async function fetchVideos() {
      const  profiles  = await fetchData('https://blue.the-centaurus.com/api/get_videos');
      console.log(profiles)
      setVideos(profiles)      
    }
    fetchVideos();
  }, []);
  useEffect(() => {  
    
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }
    fetch('https://blue.the-centaurus.com/api/user', {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
      window.location.href = '/login';
    })
  }, []);
  
  
  const handleUploadResult = (status) => {
    setUploadResult(status);
  };

  const [imageUrls, setImageUrls] = useState([]);

  const images = ["https://blue.the-centaurus.com/api/images/2010-10-13/compressed/IMG_1548.jpg", "https://blue.the-centaurus.com/api/images/2011-04-25/compressed/IMG_0872.jpg",
  "https://blue.the-centaurus.com/api/images/2013-10-19/compressed/DSCF5646.jpg", "https://blue.the-centaurus.com/api/images/2014-10-20/compressed/P1020568c.jpg", 
  "https://blue.the-centaurus.com/api/images/2015-10-28/compressed/P1070158.JPG",
  "https://blue.the-centaurus.com/api/images/2016-07-31/compressed/20160731_091459.jpg", "https://blue.the-centaurus.com/api/images/2016-08-23/compressed/20160823_095342.jpg", "https://blue.the-centaurus.com/api/images/2016-10-13/compressed/20161013_175611.jpg",  "https://blue.the-centaurus.com/api/images/2016-12-28/compressed/20161228_175725.jpg",
  "https://blue.the-centaurus.com/api/images/2017-04-19/compressed/20170419_210945.jpg", "https://blue.the-centaurus.com/api/images/2017-10-27/compressed/20171027_221922.jpg",
  "https://blue.the-centaurus.com/api/images/2018-07-07/compressed/07.07.2018%20Abiball%20HTS%202.jpg", "https://blue.the-centaurus.com/api/images/2018-03-30/compressed/WhatsApp%20Image%202022-04-22%20at%2012.31.14(1).jpeg", 
  "https://blue.the-centaurus.com/api/images/2019-08-02/compressed/IMG_20190803_001714.jpg",
  "https://blue.the-centaurus.com/api/images/2020-01-04/compressed/IMG_20200104_113001.jpg", "https://blue.the-centaurus.com/api/images/2020-02-20/compressed/IMG_20200220_101516.jpg", "https://blue.the-centaurus.com/api/images/2021-12-19/compressed/WhatsApp%20Image%202022-03-09%20at%2010.39.21.jpeg", "https://blue.the-centaurus.com/api/images/2021-09-12/compressed/5702_20210912_131042_201987439_original.JPG",
"https://blue.the-centaurus.com/api/images/2022-06-16/compressed/IMG_20220616_103415.jpg",
"https://www.the-centaurus.com/Blue/stories_images/2023-11-18/compressed/PXL_20231118_212434224.jpg"]; // Replace with your image URLs
    
useEffect(() => {
  async function fetchImages() {
    const token = localStorage.getItem('token');
    const imagePromises = images.map(async (path) => {

      const response = await fetch(path, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    });

    try {
      const imageDataUrls = await Promise.all(imagePromises);
      setImageUrls(imageDataUrls);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  }

  fetchImages();
}, []); 

  const highlight = ["Olympia", "Bergen", "Sizilien", "Cape Canaveral", "Brit. Virgin Islands", "Weltjugendtag", "Niagara Fälle", "Maker Faire Rom",  "Singapur", "Oslo", "New York","Abiball", "Segeln Karibik", "Nordkapp",  "Valencia", "35km Küsten-Lauf", "Expo Dubai", "Marathon", "Skagen", "San Francisco"]; 
  const [cardHeight, setCardHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      // Calculate the desired card height based on the screen width
      const screenWidth = window.innerWidth;
      const desiredCardHeight = screenWidth * 0.2; // You can adjust the multiplier as needed
      setCardHeight(desiredCardHeight);
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial calculation
    handleResize();

    // Add event listener to recalculate on window resize
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const cardWrapperStyle = {
    position: 'relative',
    paddingBottom: '100%', /* This creates a 1:1 aspect ratio */
    
  };

  const cardStyle = {
    background: '#111',
    borderRadius: '50%',
    position: 'absolute',
    width: '100%',
    height: '100%',
  };

  const imgStyle = {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  };
  const labelStyle = {
    position: 'absolute',
    top: '5px',
    left: '30px',
    color: '#fff',
    fontSize: '16px',
    background: 'rgba(20, 20, 20, 0.8)',
    padding: '10px',
    backdropFilter: "blur(6px)",
    zIndex: 100,
  };
  const labelStyle2 = {
    position: 'absolute',
    bottom: '-10px',
    textAlign: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)', 
    color: '#fff',
    padding: '10px',
    fontSize: '16px',
    background: 'rgba(20, 20, 20, 0.8)',
    backdropFilter: "blur(6px)",
    zIndex: 100,
  };

  return (
    <>
      <Helmet>
        <title> Highlights | Blue </title>
      </Helmet>

      <Container maxWidth="xl">  
        <Grid container spacing={10} style={{ marginTop: '-50px', paddingLeft: isMobile ? '20px' : '0px', paddingRight: isMobile ? '20px' : '0px' }}>
      {images.map((imageUrl, index) => (
        <Grid item xs={12} md={6} lg={4} key={index}>
            <a href={`/dashboard/day#${imageUrl.split('/')[5]}`} style={{textDecoration: 'none'}}>
          <div style={cardWrapperStyle}>
            <div style={labelStyle}>{imageUrl.split('/')[5]}</div>
            <Card style={cardStyle}>
              <LazyLoadImage
                style={imgStyle}
                alt={""}
                loading="lazy" 
                src={imageUrls[index]}
              />
            </Card>
            <div style={labelStyle2}>{highlight[index]}</div>
          </div></a>
        </Grid>
      ))}
    </Grid>
      </Container>
    </>
  );
}
