import React from 'react';

const PersonInfoTable = ({ data }) => {
  return (
    <table style={{ width: '100%', padding: '25px' }}>
      <tbody>
        {Object.keys(data).map((key, index) => (
             key !== 'id' &&  key !== 'name' && key !== 'vorname' && key !== 'nachname' && (
          <tr key={index}>
            <td style={{ width: '50%', padding: '10px',textAlign: 'left', border: '1px solid #000' }}>{key}</td>
            <td
                style={{ width: '50%', padding: '10px',textAlign: 'left', border: '1px solid #000' }}
                dangerouslySetInnerHTML={{
                  __html: data[key].replace(/\n/g, '<br>'),
                }}
             />
          </tr>)
        ))}
      </tbody>
    </table>
  );
};

export default PersonInfoTable;
