// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    LinearProgress,
    List, ListItem, ListItemText,
    MenuItem,
    TextField,
    TableBody,
    TableCell,
    TypographyStyle,
    Typography,
    CardHeader,
    ListItemSecondaryAction, 
    Container,
    Grid,
    Dialog, DialogTitle, DialogContent,
    IconButton,
    Snackbar ,
    Fab,
    Alert,
    TableContainer,
    TablePagination,
  } from '@mui/material';   
  import { useState ,useEffect, useRef  } from 'react';

// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------


export default function Upload({ date, isMobile, handleUploadResult, IconSize, ...other }) {
    
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currdate, setDate] = useState(date);

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemoveFile = (fileIndex) => {
    const updatedFiles = selectedFiles.filter((_, index) => index !== fileIndex);
    setSelectedFiles(updatedFiles);
  };

  
  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      return;
    }
    setLoading(true);
    setUploadProgress(0)
  
    const formData = new FormData();
    formData.append('date', currdate);
  
    for (let i = 0; i < selectedFiles.length; i += 1) {
      formData.append('files', selectedFiles[i]);
    }
  
    const xhr = new XMLHttpRequest();
  
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(progress);
      }
    });
  
    try {
      xhr.open('POST', 'https://blue.the-centaurus.com/api/upload_files');
  
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
            handleUploadResult('success');
        } else {
            handleUploadResult('failure');
        }
        setLoading(false);
        setDialogOpen(false); // Close the dialog after uploading
      };
  
      xhr.onerror = () => {
        console.error('Error uploading files');
        handleUploadResult('failure');
        setDialogOpen(false);
        setLoading(false);
      };
  
      xhr.send(formData);
    } catch (error) {
      console.error('Error uploading files:', error);
      handleUploadResult('failure');
      setDialogOpen(false);
      setLoading(false);
    }
    
  };


  const handleOpenDialog = () => {
    setSelectedFiles([]); // Clear any previously selected files when opening the dialog
    setDialogOpen(true);
    console.log(date)
    setDate(date)
    fileInputRef.current.click();
  };
  
  
 
  const handleCloseDialog = () => {
    if (loading === false){
      setDialogOpen(false);
    }
  };
  
  const handleDescriptionChange = (e) => {
    setDate(e.target.value);
  };
  return (
    <>
    <input type="file" id="uploadFile" multiple onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }}/>
    {IconSize ? (
    <IconButton edge="end" aria-label="delete" onClick={handleOpenDialog} >
    <Iconify   icon={'mdi:upload'} style={{color: dialogOpen ? "#2065D1" : undefined }} width={IconSize} />
    </IconButton>
    ) : ( <Button variant="contained"  onClick={handleOpenDialog} style={{marginTop: '10px', marginLeft: '25px'}} startIcon={<Iconify icon="eva:plus-fill" />}>
            Upload
          </Button>)}
    

    <Dialog  open={dialogOpen} onClose={loading ? undefined : handleCloseDialog} disableBackdropClick={loading} disableEscapeKeyDown={loading}>
    <div style={{ minWidth:isMobile? '100%' : 500, background: '#000'}}><DialogTitle>Select Files</DialogTitle>
    
    <TextField
          value={currdate}
          onChange={handleDescriptionChange}
          fullWidth
          variant="standard" 
          inputProps={{
            style: { textAlign: 'center', border: 'none',      // Remove the border
            boxShadow: 'none' }, // Additional styles for the input element
          }}
          
        />
          <DialogContent style={{width: '100%', background: '#000'}}>
            {selectedFiles.length >0 && (
              <List>
                {selectedFiles.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFile(index)}>
                      <Iconify   icon={'mdi:delete'} color="#ccc" width={32} />
                      </IconButton>
                    </ListItemSecondaryAction>
                    <ListItemText primary={file.name} />
                    
                  </ListItem>
                ))}
              </List>
            )}
            <input type="file" id="uploadFile" multiple onChange={handleFileChange} style={{ display: loading ? 'none' : undefined }}/>
            <LinearProgress variant="determinate" value={uploadProgress} style={{ display: loading ?  undefined : 'none'  }} />


            <Button style={{width: '100%', marginTop:'30px', height:'50px'}}  variant="contained" color="secondary"   onClick={handleUpload}  disabled={selectedFiles.length === 0 || loading}>
              Upload
            </Button>
          </DialogContent></div>
        </Dialog>

    </>     

  );
}
