
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useTheme, styled } from '@mui/material/styles';

import { sentenceCase } from 'change-case';
import { useState ,useEffect, useRef  } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import { MapContainer, TileLayer, Circle, Popup } from 'react-leaflet';

// @mui
import {
  Box,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Chip,
  Popover,
  Checkbox,
  TableRow,
  LinearProgress,
  List, ListItem, ListItemText,
  MenuItem,
  TextField,
  TableBody,
  TableCell,
  TypographyStyle,
  Typography,
  CardHeader,
  ListItemSecondaryAction, 
  Container,
  Grid,
  Dialog, DialogTitle, DialogContent,
  IconButton,
  Snackbar ,
  Fab,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components

import { useLocation } from 'react-router-dom';
import './blue.css'; // Import the CSS file
import Iconify from '../components/iconify';

import { fShortenNumber } from '../utils/formatNumber';
import {
  AppDay,
  AppCurrentVisits,
  Upload
} from '../sections/@dashboard/app';

import PersonDetails from './PersonDetails';

export default function DayPage() {
  
  const Fav = styled('fab')(({ theme }) => ({
    position: 'fixed',
    zIndex: 500,
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  }));

  const mapboxAccessToken = "pk.eyJ1IjoibGF3aXgxMCIsImEiOiJjamJlOGE1bmcyZ2V5MzNtcmlyaWRzcDZlIn0.ZRQ73zzVxwcADIPvsqB6mg"
  const [hugeOptionsArray, setOptions] = useState([{name: 'test', date: '2000-01-01'}]);

  const [options, setOptionsSimple] = useState([]);

  const [faces, setFaces] = useState(["Thore"]);
  const [facesFull, setFacesFull] = useState(["Thore"]);
  const [position, setPosition] = useState([0,0]);

  const [place, setPlace] = useState('');
  const [names, setNames] = useState('');
  const [food, setFood] = useState('');
  const [sport, setSport] = useState('');
  const [keywords, setKeywords] = useState('');
  const [country, setCountry] = useState('');
  const [highlightImage, setHighlightImage] = useState('');

  const [selectHighlightImg, setSelectHighlightImg] = useState(false);

  const imageRef =  useRef(null);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [runs, setRuns] = useState([]);

  
  const [uploadResult, setUploadResult] = useState(null);
  const [user, setUser] = useState(null);
  const [dayInfo, setDayInfo] = useState({description: '', names: '', keywords : '', country: ''});
  const [dailyCards, setDailyCards] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [gpsCoords, setGpsCoords] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check on initial render

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {  
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }
    fetch('https://blue.the-centaurus.com/api/user', {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      console.log(response)
      setUser(response.json().user);
    })
    .catch(error => {
      console.log(error);
      window.location.href = '/login';
    })
  }, []);

  useEffect(() => {  
    setIsMobile(window.innerWidth <= 768);
    async function getPersons(){
      let  profiles  = await fetchData('https://blue.the-centaurus.com/api/get_persons');
      console.log("profiles")
      profiles = JSON.parse(profiles)
      profiles = profiles.map( (item) => `${item.vorname} ${item.nachname}`) 
      console.log(profiles)
      setOptionsSimple(profiles)
    }getPersons();

  }, []);
  async function fetchImageData(url) {
    const token = localStorage.getItem('token');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
    const blob = await response.blob();
    return blob;
  }
  
  async function fetchData(url) {
    const token = localStorage.getItem('token');
    console.log(token)
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    const data = await response.json();
    return data;
  }
  useEffect(() => {
    fetchData('https://blue.the-centaurus.com/api/suggestions_with_dates')
      .then((response) => {console.log("AAAA"); console.log(response); setOptions(JSON.parse(response))});
    }, []);


  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handlePlaceChange = (e) => {
    setPlace(e.target.value);
  };
  const token = localStorage.getItem('token');
  async function postData(url, payload) {
    const token = localStorage.getItem('token');
    console.log(token)
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      });
  
      const success = response.ok;
      const data = await response.json();
      return { success, data };
    } catch (error) {
      return { success: false, data: "No internet connection or server unavailable." };
    }
  }
  
  const fetchSearch = async (url, day) => {
    if(url==="get_day"){
      setHighlightImage("");
    }
    console.log(`https://blue.the-centaurus.com/api/${url}`)
    try {
      const resp = await postData(`https://blue.the-centaurus.com/api/${url}`, { input: day});
      const responseData = resp.data;
      console.log("responseData");
      console.log(day);
      const res= JSON.parse(responseData);
      if (res.length > 0 && url==="get_day"){
        setPlace(res[0].place)
        setNames(res[0].names)
        setDescription(res[0].description)
        setFood(res[0].food)
        setSport(res[0].sport)
        setHighlightImage(res[0].highlight_image)
        setKeywords(res[0].keywords)
        setCountry(res[0].country)
        console.log("res")
        console.log(res)
        return res;
      }
      return res;
    } catch (error) {
      console.error('Error fetching data:', error);
      setHighlightImage("");
      return null;
    }
  };

  const decrDate = () => {
    const newDate = new Date(currDate);
    newDate.setDate(newDate.getDate() - 1);
    setDate(newDate);
  }
  const incrDate = () => {
    console.log("Incr")
    const newDate = new Date(currDate);
        newDate.setDate(newDate.getDate() + 1);
        setDate(newDate);
  }
  const setToday = () => {
    const newDate = new Date();
        setDate(newDate);
  }
  const initialDateFromHash = new Date(window.location.hash.substring(1));
  const [currDate, setDate] = useState(initialDateFromHash || new Date());
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);


  const handleKeyDown = (event) => {
    if (!isTextFieldFocused) {
      if (event.key === 'ArrowLeft') {
        decrDate();
      } else if (event.key === 'ArrowRight') {
        incrDate();
      }
    }
  };
  
  useEffect(() => {
    
    // Add the event listener when the component mounts
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isTextFieldFocused, currDate]); 

  const formatDate = (date) => {
    if(date.length===10){
      return date;
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  function getAverageColor(image) {
    if(dayInfo.highlight_image === undefined || dayInfo.highlight_image.length === 0){      
      document.body.style.backgroundColor = "#fff";
      return 0;
    }
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0);
    const imageData = context.getImageData(0, 0, image.width, image.height);
    const pixels = imageData.data;
    const pixelCount = image.width * image.height;
    let r = 100;
    let g = 100;
    let b = 100;
    for (let i = 0; i < pixelCount; i += 1) {
      r += pixels[i * 4];
      g += pixels[i * 4 + 1];
      b += pixels[i * 4 + 2];
    }
    r = Math.floor(r / pixelCount);
    g = Math.floor(g / pixelCount);
    b = Math.floor(b / pixelCount);
    const thresh = 50;
    if (r < thresh || g < thresh || b < thresh){
      r += thresh;
      g += thresh;
      b += thresh;
    }
    if (r > 255 -thresh  || g > 255 -thresh || b > 255 -thresh){
      r -= thresh;
      g -= thresh;
      b -= thresh;
    }
    
    const hexColor = rgbToHex(r, g, b);
    console.log(r);
    document.body.style.backgroundColor = hexColor;
    return 0;
  }

  function rgbToHex(r, g, b) {
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
  }

  function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  }

  const handleImageLoad = () =>{
    if (imageRef.current != null) {
      const loadedImage = new Image();
      loadedImage.crossOrigin = "anonymous";
      loadedImage.onload = () => {
        getAverageColor(loadedImage);
      };
      loadedImage.src = imageRef.current.src;
    }
  }
  
  useEffect(() => {
    const formattedDate = formatDate(currDate);
    window.location.hash = formattedDate;

    setTitle(formattedDate)
    async function fetch(){
      
      const parsedData = await fetchSearch('get_day', formattedDate);
      
      if (parsedData !== null && parsedData.length > 0) {
        setRuns(parsedData)
        setDayInfo(parsedData[0])
        const imageUrl = `https://blue.the-centaurus.com/api/images/${parsedData[0].date}/compressed/${parsedData[0].highlight_image}`
        
        fetchImageData(imageUrl)
        .then(blob => {
          const imageUrl = URL.createObjectURL(blob);
          if (imageRef.current) {
            imageRef.current.src = imageUrl;
          }
        })
        .catch(error => {
          console.error('Error fetching image:', error);
          imageRef.current.src = "";
        });
        const filteredNames = parsedData[0].names
            .split(';')
            .filter((name) => name.length > 1);
        setFaces(filteredNames);

        const slicedNamesCount = Math.max(filteredNames.length - 8, 0);

        // Create an array of names with the "+x names" entry if there are more than three names
        const orig =
          filteredNames.length > 8
            ? filteredNames.slice(0, 8)
            : filteredNames;

          
          let namesWithLastEntry = orig.map((fullName, id) => {
            const nameParts = fullName.split(" ");
            const vorname = nameParts[0];
            const nachname = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
            return { vorname, nachname, id };
          });
          // Sorting the adaptedArray by nachname
          namesWithLastEntry.sort((a, b) => {
            const nachnameComparison = a.nachname.localeCompare(b.nachname);
            return nachnameComparison !== 0 ? nachnameComparison : a.vorname.localeCompare(b.vorname);
          });
          
          if (slicedNamesCount > 0){
            namesWithLastEntry = [...namesWithLastEntry, {vorname: `+${slicedNamesCount}`, nachname: ''}]
          }
          console.log("namesWithLastEntry")
          console.log(namesWithLastEntry)
          setFacesFull(namesWithLastEntry);

      }else{
        setDayInfo({})
        setDescription('')
        setRuns([])
        setHighlightImage("");
        imageRef.current.src = "";
      }
    }
    fetch();
  }, [currDate,uploadResult, selectHighlightImg]);

  const handleHashChange = () => {
    const formattedDate = window.location.hash.replace('#','');
    console.log('Hash changed:', formattedDate);
    console.log(formattedDate)
    if(formattedDate.length===10){
      setDate(formattedDate )
    }else{
      setDate(new Date())
    }
    
  };
let nKeywords = [];
  if (dayInfo && dayInfo.keywords) {
    nKeywords = dayInfo.keywords.split(';').filter((name) => name.length > 1);
  }
  useEffect(() => {
    // Manually call the handleHashChange function to handle the initial hash
    handleHashChange();

    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  useEffect(() => {
    async function fetch(){
      const formattedDate = formatDate(currDate);
      console.log(formattedDate)
      const parsedData = await fetchSearch('get_images_day', formattedDate);
      if (parsedData !== null) {
        console.log(parsedData);
        setDailyCards(parsedData)
      }
    }
    fetch();
  }, [currDate,uploadResult]); 
  
  useEffect(() => {
    setNames(faces.join(';'));
  }, [faces]); 
  

  
  const filterOptions = (options, { inputValue }) => {
    // Filter the options based on user input and return the top 5 matches
    const filteredOptions = hugeOptionsArray.filter((option) =>
      option.name.substr(0, inputValue.length).toUpperCase() === inputValue.toUpperCase()
    );
    
    const sortedOptions = filteredOptions.sort((a, b) => {
      // Sort by date in descending order
      if (a.date > b.date) return -1;
      if (a.date < b.date) return 1;
    
      // If dates are equal, sort by name in ascending order
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
    
      return 0; // If both date and name are equal, maintain the original order
    });
    

    // Return the top 5 matches
    return sortedOptions.slice(0, 5);
  };
  const getOptionLabel = (option) => {
    if (option.name) {
      return option.name;
    }
    // If the option does not have a 'name' attribute, use the option itself as the label
    return option;
  };
  function isDateFormat(str) {
    // Define the regex pattern for YYYY-MM-DD format
    const pattern = /^\d{4}-\d{2}-\d{2}$/;
  
    // Test if the string matches the pattern
    return pattern.test(str);
  }
  const xDownRef = useRef(null);
  const yDownRef = useRef(null);

  const handleTouchMove = (evt) => {
    if (!xDownRef.current || !yDownRef.current) {
      return;
    }

    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;

    const xDiff = xDownRef.current - xUp;
    const yDiff = yDownRef.current - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff) && (yUp + window.scrollY) < 800) {
      // Most significant condition
      //  const current_id = 1;
      //  if (document.activeElement !== document.getElementById('descr_label')) {
        if (xDiff > 0) {
          incrDate()
        } else {
          decrDate();
       // }
      }
    } 

    // Reset values
    xDownRef.current = null;
    yDownRef.current = null;
  };

  const handleTouchStart = (evt) => {
    xDownRef.current = evt.touches[0].clientX;
    yDownRef.current = evt.touches[0].clientY;
  };
  
  
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [keywordDialog, setKeywordDialog] = useState(false);
  const [keywordInfo, setKeywordInfo] = useState([]);
  const [currKeyword, setCurrKeyword] = useState("");
  const [blurMap, setBlurMap] = useState(true);

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };


  const darkBackground = {
    backgroundColor: '#404040', // Change this to your desired dark background color
    color: 'white', // Change this to your desired text color
  };
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const fetchCoordinates = async (city, country, date) => {
    if(city=== "Auf See")
      return {"gps": false, "city": city, "date": date}
    city = (`${city};`).split(";")[0]
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?city=${city}&country=${country}&format=json`
      );

      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          const location = data[0];
          console.log(location)
          return {"gps": [location.lat, location.lon], "city": city, "date": date};
        } 
        
      } else {
        console.error("Error fetching geolocation");
      }
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }
    return {"gps": false, "city": city, "date": date}
  };
  async function getConsecutiveDates(dates) {
      setBlurMap(true)
      const result = [];
      const fetchCoordinatesPromises = [];
      const dateMap = new Map(dates.map(entry => [entry.date, entry]));

      // Convert the current date to a Date object
      const currentDate = new Date(currDate);

      let tempDate = new Date(currentDate);

      // Include dates before the currDate
      while (dateMap.has(tempDate.toISOString().split('T')[0])) {
        result.unshift(dateMap.get(tempDate.toISOString().split('T')[0]));
        tempDate.setDate(tempDate.getDate() - 1);
      }

      tempDate = new Date(currentDate);

      // Include dates after the currDate
      while (dateMap.has(tempDate.toISOString().split('T')[0])) {
        result.push(dateMap.get(tempDate.toISOString().split('T')[0]));
        tempDate.setDate(tempDate.getDate() + 1);
      }
      setKeywordInfo(result);
      
      for(let i = 0; i < result.length; i+=1){
        fetchCoordinatesPromises.push(fetchCoordinates(result[i].place, result[i].country, result[i].date));
      }
      const coordsResponses = (await Promise.all(fetchCoordinatesPromises)).filter((item) => item.gps !== false);
      setGpsCoords(coordsResponses);
      console.log(coordsResponses);
      if(coordsResponses.length > 0){
        console.log(coordsResponses.map((item) => item.gps))
        const preped = coordsResponses.map((item) => [parseFloat(item.gps[0]),parseFloat(item.gps[1])]);
        const totalPoints = preped.length;
        const center = preped.reduce(
          (accumulator, point) => [accumulator[0] + point[0], accumulator[1] + point[1]],
          [0, 0]
          );
          const averageCenter = [center[0] / totalPoints, center[1] / totalPoints];
          setPosition(averageCenter);
      }
      setBlurMap(false)
      return result;
  }
  
  
  const fetchKeywordInfo = async (searchTerm) => {
    try {
      const responseData = await postData('https://blue.the-centaurus.com/api/query', { input: searchTerm , imageDays: "dates", columns: ["keywords"], showFaces:false, limit: -1});
      console.log(responseData)
      return getConsecutiveDates(JSON.parse(responseData.data));
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };
  const handleKeywordDialog = async(keyword) => {   
    setCurrKeyword(keyword)
    setKeywordDialog(true)
    const fetchKeyInfo = await fetchKeywordInfo(keyword);
    
  };

  const handleCloseKeywordDialog = () => {
    setKeywordDialog(false);
  };
  
  const highlightImg = () => {
    setSelectHighlightImg(!selectHighlightImg)
  };
  
 
  
  const handleUploadResult = (status) => {
    setUploadResult(status);
  };

  const handleSnackbarClose = () => {
    setUploadResult(null);
  };
  const addNewFace = () => {
    setFaces([...faces, '']); 
  }
  const filterOptionsPersons = (options, { inputValue }) => {
    const filteredOptions = options.filter((option) =>
      option.substr(0, inputValue.length).toUpperCase() === inputValue.toUpperCase()
    );
    
    const sortedOptions = filteredOptions.sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0; 
    });
    return sortedOptions.slice(0, 3);
  };
  const saveData = async() => {
    const arr = [formatDate(currDate), place, names, description, food, sport, highlightImage, keywords, country ];
    console.log(arr)
    const responseData = await postData(`https://blue.the-centaurus.com/api/save_day`, arr);
    console.log(responseData.success)
    setUploadResult(responseData.success)
    setDialogOpen(false);
  };
  
  
  const handleChange = (index, newValue) => {
    const updatedFaces = [...faces];
    updatedFaces[index] = newValue;
    setFaces(updatedFaces);
   
  };
  function removeFace(indexToRemove) {
    setFaces(faces.filter((_, index) => index !== indexToRemove));
    
  }
  
  const handleHighlightImg = (event) => {
    if(selectHighlightImg){
      postData(`https://blue.the-centaurus.com/api/update_highlight_img`, { input: event});
      
      setTimeout(() => {
        setSelectHighlightImg(false)
      }, 100); 
      
    }
  };
  const IconSize = 20;

  
  return (
    <>
     <style>
        {`
          body {
            transition: background-color 0.5s ease; /* Adjust duration and timing function as needed */
          }
          .img-fade-in {
            transition: opacity 0.5s ease;
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

        `}
      </style>
      <Helmet>
        <title> Blue </title>
      </Helmet>

      <Fav>
      <Fab color="secondary" aria-label="add" onClick={saveData}>
          <Iconify  icon={'mdi:content-save'}  color="#fff" width={32} />
      </Fab>
      </Fav>

      <Dialog open={keywordDialog} onClose={handleCloseKeywordDialog}>
      <div style={{ background: '#222' }}><CardHeader style={{textAlign: 'center'}} title={`${currKeyword}`} subheader={`${keywordInfo.length} Tage`} />
      
      <Card style={{ height: '300px', minHeight: '100%' ,background: '#222', margin: '20px'}}  >
      <div style={{ position: 'relative', height: '100vh', width: '100vw', filter: (blurMap ? 'blur(10px)' : undefined)}}>
      <MapContainer key={position.toString()} center={position} zoom={4} style={{ height: '100%', width: '100%',background: '#000' }}>
        {/* Use TileLayer with 'CartoDB.DarkMatter' provider */}
        

        <TileLayer  url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`} style={{ height: '100%', width: '100%',background: '#000' }}/>
      
        {gpsCoords.map((pos) => (
          <Circle center={pos.gps} radius={60} pathOptions={{ color: '#f00' }}>            
               <Popup><a href={`/dashboard/day#${pos.date}`}>{pos.date}</a>
              <p style={{width: '100%', textAlign: 'center'}}>{pos.city}</p>
            </Popup>
          </Circle>
        ))}
          
        
      </MapContainer>
      </div>
      
      </Card>

      <table style={{ width:'90%', padding: '25px', marginLeft: '20px', marginRight: '20px', marginTop: '5px'}}>
                <tbody>
                <tr>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Date</td>
                      <td
                          style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Place</td>
                    </tr>
                    
                {keywordInfo.map((item) => (
                    <tr>
                        <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>
                      <a href={`/dashboard/day#${item.date}`}>{item.date}
                      </a></td>
                      <td
                          style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>{item.place}</td>
                    </tr>
                  ))} 

                  </tbody>
                  </table>
        
       
      </div>
      </Dialog>

      <Dialog  open={dialogOpen}  onClose={handleCloseDialog}>
      <div style={{ background: '#000'}}><DialogTitle>Personen</DialogTitle>
          <DialogContent style={{width: '100%', background: '#000'}}>
          
                {faces.map((name, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <Autocomplete
                  options={options}
                  filterOptions={filterOptionsPersons}
                  value={name}
                  PaperComponent={({ children }) => (
                      <div style={{ ...darkBackground, maxHeight: '200px', overflowY: 'auto', minWidth: '150px' , width:'100%'}}>
                      {children}
                      </div>
                  )}
                  renderInput={(params) => (
                      <TextField
                      {...params}
                      value={name}
                      InputProps={{
                          ...params.InputProps,
                          style: {
                          color: darkBackground.color,
                          background: '#111',minWidth: '150px', width:'100%'
                        },
                      }}
                      inputProps={{
                          ...params.inputProps,
                          style: {
                          color: darkBackground.color,
                          background:  '#111',minWidth: '150px', width:'100%'
                          },
                      }}
                      />
                  )}
                  onInputChange={(event, newValue) => handleChange(index, newValue)}

                  />
                  <IconButton edge="end" aria-label="delete" onClick={() => removeFace(index)} >
                  <Iconify   icon={'mdi:delete'} style={{color: '#aaa' }} width={32} />
                  </IconButton>
                  </div>
                ))}
            
            <Button
                variant="contained"
                
                style={{ width: '100%',  height: '50px', background: '#303030' }}
                onClick={addNewFace}>
                + Person
              </Button>

            <Button style={{width: '100%', marginTop:'10px', height:'50px'}}  variant="contained" color="secondary"  onClick={saveData}>
              Speichern
            </Button>
          </DialogContent></div>
        </Dialog>

      <Container maxWidth="xl">  
      
      <Grid container spacing={2}  style={{marginTop: '-25px'}}>       
          <Grid item xs={12} md={12} lg={6} > 
          <div onTouchMove={handleTouchMove} onTouchStart={handleTouchStart}>
            <Card style={{height: isMobile ? '300px' : '400px', background: '#000'}}>        
            <img ref={imageRef}  onLoad={() => { handleImageLoad();  }}  style={{height: '100%', width: '100%', objectFit:'cover',  transition: 'opacity 0.5s ease', background: '#999'}}  alt=""/>
            
            <div style={{ position: 'absolute', bottom: '16px', left: '16px'}}>
              <Fab style={{background: '#111'}} aria-label="add" onClick={decrDate}>
                <Iconify icon={'mdi:arrow-left'} color="#fff" width={32} />
              </Fab>
            </div>

            <div style={{ position: 'absolute', bottom: '16px', right: '16px' }}>
              <Fab style={{background: '#111'}} aria-label="add" onClick={incrDate}>
                <Iconify icon={'mdi:arrow-right'} color="#fff" width={32} />
              </Fab>
            </div>
            
            </Card> 
            </div> 
            <Grid container spacing={0.5} style={{ marginTop: '6px', marginBottom: '-20px' }}>
                <Grid item xs={12} md={12} lg={12} >
                <Card style={{ background: '#111', borderRadius: '16px', padding: '0px', height: '33px' }}>
                  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(50px, 1fr))', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
                    <IconButton edge="end" aria-label="left" onClick={decrDate} style={{ display: 'none' }}>
                      <Iconify icon={'mdi:arrow-left'} width={IconSize} />
                    </IconButton>
                    <Upload IconSize={IconSize} date={formatDate(currDate)} isMobile={isMobile} handleUploadResult={handleUploadResult} />
                    <IconButton edge="end" aria-label="delete" onClick={highlightImg}>
                      <Iconify icon={'mdi:edit'} style={{ color: selectHighlightImg ? '#2065D1' : undefined }} width={IconSize} />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={handleOpenDialog}>
                      <Iconify icon={'mdi:person'} width={IconSize} />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={setToday}>
                      <Iconify icon={'mdi:calendar-today'} width={IconSize} />
                    </IconButton>
                    <IconButton edge="end" aria-label="right" onClick={incrDate} style={{ display: 'none' }}>
                      <Iconify icon={'mdi:arrow-right'} width={IconSize} style={{ marginTop: '-20px' }} />
                    </IconButton>
                  </div>
                </Card>


                </Grid>
              </Grid>

          
            <Grid container spacing={0.5} style={{marginTop: '25px', marginBottom: '-10px', marginLeft: '1px'}} >   
            <Box style={{ display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                gap: '5px',}}>
                {nKeywords.map((name) => (
                        <Chip
                        style={{
                          background: '#111',
                          color: '#eee',
                        }}
                        onClick={() => handleKeywordDialog(name)}
                        label={name}
                        />
                    ))}
              </Box>
              
            </Grid>

            
          </Grid>
        <Grid item xs={12} md={12} lg={6}>  
        <div >
    <Card style={{ background: '#111', color: '#ccc', flexGrow: 1 }}>

    <Autocomplete
      options={hugeOptionsArray}
      getOptionLabel={getOptionLabel}
      value={title}
      filterOptions={filterOptions} // Use the custom filter function
      PaperComponent={({ children }) => (
        <div style={{ ...darkBackground, maxHeight: '200px', overflowY: 'auto' }}>
          {children}
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          value={title}
          InputProps={{
            ...params.InputProps,
            style: {
              color: darkBackground.color,
              background: '#111',
              textAlign: 'center',
              marginRight: '-50px',
            },
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              color: darkBackground.color,
              background: '#111',
              textAlign: 'center',
              marginRight: '-50px',
            },
          }}
        />
      )}
      onInputChange={(event, newValue) => {
        console.log(newValue);
        if (newValue.includes('(') && newValue.includes(')')) {
          const nd = new Date(newValue.replace(')', '').split('(')[1]);
          console.log(nd);
          setDate(nd);
        } else if (isDateFormat(newValue)) {
          const nd = new Date(newValue);
          console.log(nd);
          setDate(nd);
        }
        setTitle(newValue);
      }}
    />
    <TextField
          value={place}
          onChange={handlePlaceChange}
          fullWidth
          variant="standard" 
          inputProps={{
            style: { color: '#fff', textAlign: 'center', border: 'none',      // Remove the border
            boxShadow: 'none' }, // Additional styles for the input element
          }}
        />
        
        
        <TextField
          fullWidth
          multiline
          variant="standard" 
          style={{
            height: isMobile ? '100%' : '312px', // Set an appropriate height for the TextField
            overflow: 'auto', // Enable scrolling when content overflows
            color: 'white',
            border: 'none', // Remove the border
            boxShadow: 'none', // Remove the box shadow
            outline: 'none', 
          }}
          inputProps={{
            readOnly: false,
            style: {
              color: 'white',
              border: 'none',      // Remove the border
              boxShadow: 'none',   // Remove the box shadow
              outline: 'none',     // Remove the outline
              padding: '20px'
            },}}
          value={description}
          onChange={handleDescriptionChange}
          onFocus={() => { console.log("focus");setIsTextFieldFocused(true)}}
        onBlur={() => { console.log("focusfalse");setIsTextFieldFocused(false)}}
        />

      </Card>
        </div>
        <Grid item xs={12} md={12} lg={12}>  
      <Grid container spacing={0.5} style={{ marginTop: '10px' }}>
      <Box style={{ display: 'flex',
        flexWrap: 'wrap',
        marginLeft: '3px',
        width: '100%',
        gap: '5px',}}>
          <PersonDetails person={facesFull} isMobile={isMobile} />
          </Box>
      </Grid>
        </Grid>
        </Grid>


        
        <Grid item xs={12} md={12} lg={12} >
        <AppDay data={dailyCards} runs={runs} fShortenNumber={fShortenNumber} selectHighlightImg={selectHighlightImg ? handleHighlightImg : undefined}/>
      </Grid>

         
       


        </Grid>
      </Container>

      <Snackbar open={uploadResult !== null} autoHideDuration={3000} onClose={handleSnackbarClose}>
          {uploadResult !== null && (<Alert onClose={handleSnackbarClose} severity={(uploadResult === 'success' || uploadResult===true ) ? 'success' : 'error'}>
            {(uploadResult === 'success' || uploadResult===true ) ? 'Files uploaded successfully!' : 'Failed to upload files.'}
          </Alert>)}
      </Snackbar>
    </>
  );
}