import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Polyline, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Card, CardHeader, Box, IconButton, Grid } from '@mui/material';
import 'leaflet-providers'; // Import leaflet-providers correctly (no destructuring)

export default function AppRunRoute({ center, zoom, latLngPoints, ...other }) {
  const [mapCenter, setMapCenter] = useState(center); // Convert center prop to state
  const [mapZoom, setMapZoom] = useState(zoom); // Convert zoom prop to state
  const [key, setKey] = useState(0); // Create a state for the key

  useEffect(() => {
    // Whenever the center prop changes, update the mapCenter state
    setMapCenter(center);
  }, [center]);

  useEffect(() => {
    // Whenever the zoom prop changes, update the mapZoom state
    setMapZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    // Whenever either the center or zoom prop changes, update the key to force a re-mount of MapContainer
    setKey(prevKey => prevKey + 1);
  }, [center, zoom]);

  const polylineStyle = { color: '#002b7d' }; // Customize the polyline color here
  const mapboxAccessToken = "pk.eyJ1IjoibGF3aXgxMCIsImEiOiJjamJlOGE1bmcyZ2V5MzNtcmlyaWRzcDZlIn0.ZRQ73zzVxwcADIPvsqB6mg"
  return (
    <Grid item xs={12} md={12} lg={12}>
      <Card style={{ height: '350px', background: 'none' }} {...other}>
        {/* Use MapContainer without LeafletProvider */}
        <MapContainer
          key={key}
          center={mapCenter}
          zoom={mapZoom}
          style={{ height: '100%', width: '100%' }}>
          {/* Use TileLayer with 'OpenStreetMap' provider */}
          <TileLayer url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`} />

          {/* Display the route as a Polyline */}
          <Polyline positions={latLngPoints} pathOptions={polylineStyle} />
        </MapContainer>
      </Card>
    </Grid>
  );
}
