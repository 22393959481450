import * as React from "react";


import 'leaflet/dist/leaflet.css';
import { decode } from '@mapbox/polyline';
import 'leaflet-providers'; // Import leaflet-providers correctly (no destructuring)
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
} from "@mui/material";



export default function Chat({ polylines, info, ...other  }) {
  const [input, setInput] = React.useState("");
  const [showIcon, setShowIcon] = React.useState(true);
  
const [messages, updateMessages] = React.useState([
    { id: 1, text: "How can I help today?", sender: "bot" },
  ]);


  const handleSend = () => {
    if (input.trim() !== "") {
        setShowIcon(false);
        console.log(input);
        setInput("");
        const newMessage = { id: messages.length + 1, text: input, sender: "user" };
        updateMessages([...messages, newMessage]);
    }
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };
  
  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevents the default behavior of the Enter key (e.g., form submission)
      handleSend(); // Call the handleSend function when Enter key is pressed
    }
  };

  return (
    <Box
      sx={{
        height: "115%",
        display: "flex",
        marginTop: "-20px",
        flexDirection: "column",
        borderRadius: "30px",
        backgroundImage: `url(/assets/amrum.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
        <div style={{
            height: "100%",
            borderRadius: "30px 30px 0px 0px",
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(1px)', }}>

      <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }}>
        {messages.map((message) => (
          <Message key={message.id} message={message} />
        ))}
      </Box>
      <img src={"/assets/blue.png"} alt={""} height={"150px"}  style={{
        display: showIcon ? "block": "none",
        margin: "auto",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    }}/>
      
      </div>
      <Box sx={{ p: 2, backgroundColor: "#000" }}>
        <Grid container spacing={2}>
          <Grid item xs={10} md={11} lg={11} >
            <TextField
              fullWidth
              size="small"
              placeholder="Type a message"
              variant="outlined"
              value={input}
              onKeyDown={handleKeyDown}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              size="large"
              color="secondary"
              variant="contained"
              onClick={handleSend}
            />
          </Grid>
        </Grid>
      </Box>
      
    </Box>
  );
};

const Message = ({ message }) => {
  const isBot = message.sender === "bot";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isBot ? "flex-start" : "flex-end",
        mb: 2,
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          backgroundColor: isBot ? "#111" : "secondary.dark",
          borderRadius: isBot ? "20px 20px 20px 5px" : "20px 20px 5px 20px",
        }}
      >
        <Typography variant="body1">{message.text}</Typography>
      </Paper>
    </Box>
  );
};