import { Helmet } from 'react-helmet-async';
// @mui
import { useState ,useEffect } from 'react';

import { Grid, Button, Container, Stack, Typography, Card } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// components
import Iconify from '../components/iconify';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../sections/@dashboard/blog';
// mock
import POSTS from '../_mock/blog';
import {
  AppDay,
  Upload
} from '../sections/@dashboard/app';
import { fShortenNumber } from '../utils/formatNumber';
// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function BlogPage() {
  const [videos, setVideos] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  
  const [uploadResult, setUploadResult] = useState(null);
  
  async function fetchData(url) {
    const token = localStorage.getItem('token');
    console.log(token);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    
    let  data = await response.json();
    try {
      data = JSON.parse(data);    
    } catch (error) {
      console.log("err")
    }
  
    return data;
  }
  useEffect(() => {  
    async function fetchVideos() {
      const  profiles  = await fetchData('https://blue.the-centaurus.com/api/get_videos');
      console.log(profiles)
      setVideos(profiles)      
    }
    fetchVideos();
  }, []);
  useEffect(() => {  
    setIsMobile(window.innerWidth <= 768);
    
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }
    fetch('https://blue.the-centaurus.com/api/user', {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
      window.location.href = '/login';
    })
  }, []);
  
  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  
  const handleUploadResult = (status) => {
    setUploadResult(status);
  };

  return (
    <>
      <Helmet>
        <title> Videos | Blue </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Videos
          </Typography>
          <Upload date={"Dokumente"} isMobile={isMobile}  handleUploadResult={handleUploadResult}/>

        </Stack>

          <Grid item xs={12} md={12} lg={12}>
            <AppDay data={videos} imageDays={videos} fShortenNumber={fShortenNumber}  runs={[]}/>
          </Grid>
        
        
      </Container>
    </>
  );
}
