import { Helmet } from 'react-helmet-async';
import { filter, toInteger } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState ,useEffect } from 'react';
import Alert from '@mui/material/Alert';

// @mui
import {
    Card, CardHeader, Box, 
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  Snackbar,
  TableRow,
  MenuItem,
  Chip,
  Grid,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Pagination,
  TablePagination,
  CircularProgress, LinearProgress
} from '@mui/material';
import ReactApexChart from 'react-apexcharts';

import { Icon } from '@iconify/react';
// components
import { useLocation } from 'react-router-dom';
import { useChart } from '../components/chart';

import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock

import { fShortenNumber } from '../utils/formatNumber';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'date', label: 'Datum', alignRight: false },
  { id: 'distance', label: 'Km', alignRight: false },
  { id: 'time', label: 'Dauer', alignRight: false },
  { id: 'average_speed', label: 'Avg Speed', alignRight: false },
  { id: 'total_elevation_gain', label: 'Elev Gain', alignRight: false },
  { id: 'start_time', label: 'Start Time', alignRight: false },
  { id: '' },
];
const darkModeStyles = {
    background: '#222', 
    color: '#fff', // Light text color
  };
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    
    if (query) {
      const filteredArray = array.filter((_user) => {
        const lowerCaseQuery = query.toLowerCase();
        const nameMatch = _user.name.toLowerCase().indexOf(lowerCaseQuery) !== -1;
        const tagsMatch = _user.date.toLowerCase().indexOf(lowerCaseQuery) !== -1;
        return nameMatch || tagsMatch;
      });
      
      const stabilizedFiltered = filteredArray.map((el, index) => [el, array.indexOf(el)]);
      
      stabilizedFiltered.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      
      return stabilizedFiltered.map((el) => el[0]);
    }     
    stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
    });      
    return stabilizedThis.map((el) => el[0]);
  }
  



export default function RunsPage() {
  const location = useLocation();

  const [USERLIST, setUserList] = useState([]);
  const [chartOptions, setChartOptions] = useState(null);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(1);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);
  const [yHist, setyHist] = useState([]);

  const [orderBy, setOrderBy] = useState('date');

  const [filterName, setFilterName] = useState('');
  const [kmFilter, setKmFilter] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isMobile, setIsMobile] = useState(false);
  
  const [uploadResult, setUploadResult] = useState(null);
  const [totalDist, setTotalDist] = useState(0);
  const [runs, setRuns] = useState([]);
  const [isNotFound, setIsNotFound] = useState(false);
  const kms = [1, 5, 10, 15, 20, 21, 25, 27, 35, 42, "X"]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 50); 
  };



  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value);
  };
  
  
  
  


  const [user, setUser] = useState(null);
  const [years, setYears] = useState(["2020", "2021", "2023"]);
  const [options, setOptions] = useState(["Km Histogram", "DayTime Histogram", "Km/Avg Speed"]);
  const [currOption, setCurrOption] = useState("Km Histogram");

  useEffect(() => {  
    setIsMobile(window.innerWidth <= 768);
    
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }
    fetch('https://blue.the-centaurus.com/api/user', {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      console.log(response)
      setUser(response.json().user);
    })
    .catch(error => {
      console.log(error);
      window.location.href = '/login';
    })
  }, []);
  
  useEffect(() => {  
    setTimeout(() => {
      setFilterName("2")
    }, 1000); 
  }, []);
  
  async function postData(url, payload) {
    const token = localStorage.getItem('token');
    console.log(token)
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    return data;
  }
  
  useEffect(() => {  
    async function fetchUsers() {
    const responseData = await postData('https://blue.the-centaurus.com/api/query', { input: "", imageDays: "runs", columns: [], showFaces:false, limit: -1});
    const profiles=  JSON.parse(responseData);
      console.log(profiles)
      setTotalUsers(profiles.length)
      setUserList(profiles);
      
      setYears( [... Array.from(new Set(profiles.map((item) => item.start_date_local.split("-")[0])))
      .sort((a, b) => parseInt(a, 10) - parseInt(b, 10)) , "X"] )
    }
    fetchUsers();
    
  }, [uploadResult]);

  useEffect(() => {  
    //  console.log(USERLIST)
    let filtrUsers = USERLIST;
    if (kmFilter > 0)
      filtrUsers = USERLIST.filter((item) => item.distance >= kmFilter * 1000 && item.distance < kmFilter * 1000 + 1000)
    if (kmFilter === 15)
      filtrUsers = USERLIST.filter((item) => item.distance >= 15000 && item.distance < 20000)
    if (kmFilter === 21)
      filtrUsers = USERLIST.filter((item) => item.distance >= 21000 && item.distance < 25000)
    if (kmFilter === 42)
      filtrUsers = USERLIST.filter((item) => item.distance >= 42000)
    console.log(filtrUsers)
    //  console.log(applySortFilter(filtrUsers, getComparator(order, orderBy), filterName))
    const runs2 = applySortFilter(filtrUsers, getComparator(order, orderBy), filterName);
    console.log("runs2")
    console.log(runs2)
    setIsNotFound( !runs2.length && !!filterName);
    setRuns(runs2)


    if(runs2 && runs2.length > 0){
       setTotalDist((runs2.reduce((total, entry) => total + entry.distance, 0) / 1000).toFixed(1));

    const xHistogram = [];
    const yHistogram = [];
    if(currOption === "Km Histogram"){
      const binSize = 1000; // Define the size of each bin
      const maxDistance = Math.max(...runs2.map(run => run.distance));
      const numBins = Math.ceil(maxDistance / binSize);

      const binFrequencies = new Array(numBins).fill(0);

      runs2.forEach(run => {
      const binIndex = Math.floor(run.distance / binSize);
      binFrequencies[binIndex] += 1;
      });

      
      for (let binIndex = 0; binIndex < numBins; binIndex+=1) {
          const binCenter = (binIndex) * binSize / 1000;

          if(binCenter < 15 || binFrequencies[binIndex] > 0){
              xHistogram.push(binCenter);
              yHistogram.push(binFrequencies[binIndex]);
          }
      }
    }else if(currOption === "Km/Avg Speed"){
      const binSize = 1000; // Define the size of each bin
      const maxDistance = Math.max(...runs2.map(run => run.distance));
      const numBins = Math.ceil(maxDistance / binSize);

      const binTotalTime = new Array(numBins).fill(0);
      const binFrequencies = new Array(numBins).fill(0);

      runs2.forEach(run => {
        const binIndex = Math.floor(run.distance / binSize);
        if (run.average_speed && run.average_speed !== undefined ){
          binTotalTime[binIndex] += run.average_speed;
          binFrequencies[binIndex] += 1;
        }
       
      });

      
      for (let binIndex = 0; binIndex < numBins; binIndex+=1) {
          const binCenter = (binIndex) * binSize / 1000;

          if(binCenter < 15 || binFrequencies[binIndex] > 0){
              xHistogram.push(binCenter);
              yHistogram.push(binTotalTime[binIndex] / binFrequencies[binIndex]);
          }
      }
    }else{
      const binSize = 1; // Define the size of each bin
      const maxDistance = 24;
      const numBins = Math.ceil(maxDistance / binSize);

      const binFrequencies = new Array(numBins).fill(0);

      runs2.forEach(run => {
      const binIndex = Math.floor(parseInt(run.start_time.split(':'), 10) / binSize);
      binFrequencies[binIndex] += 1;
      });
      
      for (let binIndex = 0; binIndex < numBins; binIndex+=1) {
          const binCenter = (binIndex) * binSize;
            xHistogram.push(binCenter);
            yHistogram.push(binFrequencies[binIndex]);          
      }
    }

    console.log("xHistogram")
    console.log(xHistogram)
    console.log(yHistogram)
    setyHist(yHistogram)

    setChartOptions({
        plotOptions: { bar: { columnWidth: '50%' } },
        fill: { type: runs2.map((i) => i.fill) },
        labels: xHistogram,
        xaxis: {
          type:  '',
          labels: {
            style: {
              colors: '#eee', 
            },
          },
        },
        legend: {
          labels: {
            colors: '#fff', 
          },
        },
        yaxis: [
            {
                seriesName: 'Distanz',
                min: 0,
                forceNiceScale: true,
                labels: {
                  style: {
                    colors: '#eee', // Set y-axis label color to white
                  },
                  formatter: (value) => value.toFixed(0),
                },
                tooltip: {
                  enabled: true,
                },
            }
            
        ]
      }); 
    
    }
  }, [filterName, currOption, kmFilter]);
  
  
  const handleSnackbarClose = () => {
    setUploadResult(null);
  };



  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  return (
    <>
      <Helmet>
        <title> Runs | Blue </title>
      </Helmet>
      
      <Container>

      {options.map((xlabel) => (
          <Chip
            label={xlabel}
            onClick={() => setCurrOption(xlabel)}
            style={{
              background: '#111',
              color: '#eee',
              marginRight: '10px',marginBottom: '10px'
            }}
          />
        ))}
        
      <Grid item xs={12} md={12} lg={12} >            
      <Card style={{ height: "100%", ...darkModeStyles }}>
        
        <CardHeader title={`${filterName} (total: ${totalDist} km)`} style={{ color: '#fff' }} />

        <Box sx={{ p: 1, pb: 1 }} dir="ltr">
            <ReactApexChart type="line" series={[
                {
                  name: 'Pace',
                  type: 'bar',
                  fill: 'solid',
                  data: yHist,
                }
              ]} options={useChart(chartOptions)} height={300} />
        </Box>
        </Card>
        </Grid>
        {years.map((xlabel) => (
          <Chip
            label={xlabel}
            onClick={() => {setFilterName(xlabel === "X" ? "": xlabel)}}
            style={{
              background: '#111',
              color: '#eee',
              marginRight: '10px',
              marginTop: '10px'
            }}
          />
        ))}
        <div>
        {kms.map((xlabel) => (
          <Chip
            label={xlabel}
            onClick={() => {setKmFilter(xlabel === "X" ? 0: xlabel)}}
            style={{
              background: '#111',
              color: '#eee',
              marginRight: '10px',
              marginTop: '10px'
            }}
          />
        ))}
        </div>
        <Card style={{background: '#222', color:'#ccc', marginTop: '10px'}}>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, width: '100%' }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  firstCheckBox
                />
                <TableBody>
                  {runs.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage).map((row) => {
                    
                    const selectedUser = selected.indexOf(row.id) !== -1;
                    
                    return (
                      <TableRow hover key={row.id} tabIndex={-1} role="checkbox" selected={selectedUser} >
                      
                        <TableCell padding="checkbox"  style={{ border: '1px solid #000' }}>
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, row.id)} />
                        </TableCell>

                        
                        <TableCell  style={{ border: '1px solid #000' }} align="left"><a href={`/dashboard/run#${row.id}`} target='_blank' rel="noreferrer" >{row.name}</a></TableCell>
                        <TableCell  style={{ border: '1px solid #000' }} align="left"><a href={`https://blue.the-centaurus.com/dashboard/day#${row.start_date_local}`} target='_blank' rel="noreferrer" >{row.start_date_local}</a></TableCell>
                        
                        <TableCell  style={{ border: '1px solid #000' }} align="left">{ (row.distance / 1000).toFixed(2)}</TableCell>
                        <TableCell  style={{ border: '1px solid #000' }} align="left">{formatTime(row.moving_time)}</TableCell>
                        <TableCell  style={{ border: '1px solid #000' }} align="left">{row.average_speed.toFixed(2)}</TableCell>
                        <TableCell  style={{ border: '1px solid #000' }} align="left">{row.total_elevation_gain}</TableCell>
                        <TableCell  style={{ border: '1px solid #000' }} align="left">{row.start_time}</TableCell>
                       
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isNotFound && (
                  <TableBody style={{background: '#222'}}>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',background: '#222'
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
            <Pagination
              color="primary"
              shape="rounded"
              count={Math.ceil(runs.length / rowsPerPage)}
              page={page}
              style={{ margin: '0 auto' }}
              onChange={handleChangePage}
            />
          </div>

            
        </Card>
      </Container>



      <Snackbar open={uploadResult !== null} autoHideDuration={3000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={uploadResult === 'success' ? 'success' : 'error'}>
            {uploadResult === 'success' ? 'Files uploaded successfully!' : 'Failed to upload files.'}
          </Alert>
        </Snackbar>
    </>
  );
}