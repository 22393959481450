// component
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/blue.png`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: <Iconify icon={'mdi:chart-bar'} color='#999' />,
  },
  {
    title: 'day',
    path: '/dashboard/day',
    icon: <Iconify icon={'mdi:calendar'} color='#999' />,
  },
  {
    title: 'chat',
    path: '/dashboard/chat',
    icon: <Iconify icon={'mdi:chat'} color='#999' />,
  },
  {
    title: 'files',
    path: '/dashboard/files',
    icon: <Iconify icon={'mdi:files'} color='#999' />,
  },
  {
    title: 'persons',
    path: '/dashboard/persons',
    icon: <Iconify icon={'mdi:people'} color='#999' />,
  },
  {
    title: 'maps',
    path: '/dashboard/maps',
    icon: <Iconify icon={'mdi:location'} color='#999' />,
  },
  {
    title: 'videos',
    path: '/dashboard/blog',
    icon: <Iconify icon={'mdi:video'} color='#999' />,
  },
  {
    title: 'joggen',
    path: '/dashboard/runs',
    icon: <Iconify icon={'mdi:run'} color='#999' />,
  },
  {
    title: 'Highlights',
    path: '/dashboard/Highlights',
    icon: <Iconify icon={'mdi:star'} color='#999' />,
  }
];

export default navConfig;
