import * as React from 'react';

import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTheme, styled } from '@mui/material/styles';
import { Grid, Card, CardHeader,Avatar, Typography , Paper  } from '@mui/material';

import { Icon } from '@iconify/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';
import { AppCurrentVisits } from '.';
import Scrollbar from '../../../components/scrollbar';
import MyImageGallery from './MyImageGallery';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AppDay.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function AppDay({ data, handleToggle, imageDays, showDecider, runs, fShortenNumber, showOnlyImages, selectHighlightImg, ...other }) {

    const [alignment, setAlignment] = React.useState('dates');

    const picturePaths = [];
    const documentPaths = [];

    // Function to check if a given path is a picture
    function isPicture(path) {
    const pictureExtensions = [".png", ".jpg", ".jpeg", ".gif", ".bmp", ".mp4", ".mov", ".wmv", ".avi"];
    const extension = path.substring(path.lastIndexOf(".")).toLowerCase();
    return pictureExtensions.includes(extension);
    }
    const extensionIcons = {
        png: { icon: 'file-image', color: '#e57373' },
        jpg: { icon: 'file-image', color: '#e57373' },
        jpeg: { icon: 'file-image', color: '#e57373' },
        gif: { icon: 'file-image', color: '#e57373' },
        bmp: { icon: 'file-image', color: '#e57373' },
        pdf: { icon: 'file-pdf', color: '#64b5f6' },
        docx: { icon: 'file-word', color: '#81c784' },
        ppt: { icon: 'file-powerpoint', color: '#ffab91' },
        pptx: { icon: 'file-powerpoint', color: '#ffab91' },
        xls: { icon: 'file-excel', color: '#4db6ac' },
        xlsx: { icon: 'file-excel', color: '#4db6ac' },
        mp3: { icon: 'file-music', color: '#9575cd' },
        wav: { icon: 'file-music', color: '#9575cd' },
        ogg: { icon: 'file-music', color: '#9575cd' },
        mp4: { icon: 'file-video', color: '#f06292' },
        avi: { icon: 'file-video', color: '#f06292' },
        mkv: { icon: 'file-video', color: '#f06292' },
        mov: { icon: 'file-video', color: '#f06292' },
        // Add more extensions and corresponding Iconify icons/colors as needed
      };
      

    // Iterate through the array and separate the paths
    data.forEach(item => {
        if(item.path){
            if (isPicture(item.path)) {
                picturePaths.push(item);
            } else {
                documentPaths.push(item);
            }
        }else{
            picturePaths.push(item);
        }
    });


  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {      
      handleToggle(newAlignment);
      setAlignment(newAlignment);
    }
  };
  return (
    <>
    {showDecider && (
  <Grid item xs={12} md={12} lg={12}>
    <div style={{ display: 'flex' }}>
      <ToggleButtonGroup
        value={alignment}
        exclusive
        fullWidth
        onChange={handleAlignment}
        aria-label="text alignment"
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '10px',
          backgroundColor: '#111', // Add this line to change the background color to black
        }}
      >
        <ToggleButton value="dates" sx={{ '&.Mui-selected': { backgroundColor: '#2065D1', color: 'white' } }}   selected={imageDays === "dates"}>
          Daten
        </ToggleButton>
        <ToggleButton value="images" sx={{ '&.Mui-selected': { backgroundColor: '#2065D1', color: 'white' } }}  selected={imageDays === "images"}>
          Bilder
        </ToggleButton>
        <ToggleButton value="runs" sx={{ '&.Mui-selected': { backgroundColor: '#2065D1', color: 'white' } }}   selected={imageDays === "runs"}>
          Joggen
        </ToggleButton>
        <ToggleButton value="classification" sx={{ '&.Mui-selected': { backgroundColor: '#2065D1', color: 'white' } }}   selected={imageDays === "classification"}>
          Class
        </ToggleButton>
      </ToggleButtonGroup>

      <Typography variant="body1" align="right" sx={{ margin: '10px', flex: 1, textAlign: 'right' }}>
      {fShortenNumber(picturePaths.length + documentPaths.length) } {picturePaths.length + documentPaths.length > 0 && (<Icon icon={imageDays === "runs" ? `mdi:run`: `mdi:files`} color="#eee" width={20} style={{marginLeft: '5px', marginTop: '-5px'}} />)}
      </Typography>
    </div>
  </Grid>
)}


    <Grid container spacing={2}>
    {showDecider && documentPaths.length + picturePaths.length === 0 && (<Grid item xs={12} md={12} lg={12}><Paper
        sx={{
          textAlign: 'center',background: '#222', width: '100%', marginTop: '5px', padding:'50px'
        }}
      >
        <Typography variant="h6" paragraph>
          Nothing found
        </Typography>
        <Typography variant="body2">
          Try checking for typos or using complete words.
        </Typography>
        <Typography variant="body2">
          Maybe check filters as well
        </Typography>
      </Paper></Grid>)}
                        
    {!showOnlyImages && runs.length > 0 && runs[0].id && runs[0].id !== null && (<AppCurrentVisits
              data={ runs }
              fShortenNumber = {fShortenNumber}
            />)}
     {!showOnlyImages && documentPaths.length > 0 && (
        <Grid item xs={12} md={6} lg={4}><Card sx={{ height: '330px', width: '100%', background: '#111', color: '#eee' }}>
            <CardHeader title={documentPaths.length === 1 ? "Ein Dokument" : `${documentPaths.length}  Dokumente`}  />
            <Scrollbar style={{ overflow: 'auto', height: '280px' }}> 
            {documentPaths.map(item => {
            const extension = item.path.substring(item.path.lastIndexOf(".") + 1).toLowerCase();
            const { icon, color } = extensionIcons[extension] || { icon: 'file', color: '#9e9e9e' };  // Use a default icon if the extension is not in the mapping
            const filename = item.path.substring(item.path.lastIndexOf("/") + 1, item.path.length);

            return (
                <Grid item xs={12} md={12} lg={12}>
                    
                    <a href={`https://blue.the-centaurus.com/api/images/${item.date}/${filename}`} rel="noreferrer" target='_blank'>
                <Card sx={{ width: '100%', background: '#111', color: '#eee' }}>
                    <CardHeader
                    avatar={<Avatar sx={{ background: color }}><Icon icon={`mdi:${icon}`} color="#fff" width={32} /></Avatar>}
                    subheader={filename}
                    style={{ marginBottom: '10px' }}
                    />
                </Card></a>
                </Grid>
            );
            })}
        </Scrollbar>
        </Card>
        </Grid>
        )}
          <MyImageGallery faceBoxes = {alignment === 'faces'} picturePaths={picturePaths} selectHighlightImg={selectHighlightImg} imageDays={imageDays} fShortenNumber={fShortenNumber} showDecider={showDecider} docsLength={documentPaths.length}/>
    </Grid>
    </>
  );
}
