import React from 'react';

const formatTime = (timeInSeconds) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = timeInSeconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const RunInfoTable = ({ run, calories, elevLow, elevHigh , prCount, weather, dehydrationVolume}) => {
  const tableData = [
    { label: 'Name', value: run.name },
    { label: 'Distance', value: `${(run.distance / 1000).toFixed(3)} km` },
    { label: 'Moving Time', value: formatTime(run.moving_time) },
    { label: 'Elapsed Time', value: formatTime(run.elapsed_time) },
    { label: 'Average Speed', value: `${run.average_speed.toFixed(1)} kmh` },
    { label: 'Max Speed', value: `${run.max_speed.toFixed(1)} kmh` },
    { label: 'Total Elevation Gain', value: `${run.total_elevation_gain} m` },
    { label: 'Lowest Elevation', value: `${elevLow} m` },
    { label: 'Highest Elevation', value: `${elevHigh} m` },
    { label: 'Start Time', value: `${run.start_time} Uhr` },
    { label: 'Calories', value: `${calories} kcal` },
  ];
  
  if (prCount.length > 0) {
    tableData.push({ label: 'Personal Records', value: `${prCount}` });
  }
  if (weather.length > 0) {
    tableData.push({ label: 'Weather', value: `${weather}` });
  }
  if (weather.length > 0) {
    tableData.push({ label: 'Dehydration', value: `${dehydrationVolume} mL` });
  }

  return (
    <><table style={{ width: '100%' , margin: '25px'}}>
      <tbody>
        {tableData.map((row, index) => (
           <tr key={index}>
            <td style={{ width: '50%', paddingBottom: '10px' }}>{row.label}</td>
            <td style={{ width: '50%', paddingBottom: '10px' }} dangerouslySetInnerHTML={{ __html: row.value }} />
            </tr>
        ))}
      </tbody>
      {run.id > 10000 && (
    <a href={`https://www.strava.com/activities/${run.id}`}  rel="noreferrer" target='_blank'>Show in Strava</a>)}
    </table></>
  );
};

export default RunInfoTable;
