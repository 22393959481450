import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState ,useEffect } from 'react';
import { useTheme, styled } from '@mui/material/styles';

import Alert from '@mui/material/Alert';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  Snackbar,
  TableRow,
  MenuItem,
  Grid,
  Fab,
  Dialog,
  TextField,
  DialogTitle,
  TableBody,
  DialogContent,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Pagination,
  CircularProgress, LinearProgress
} from '@mui/material';
import { Icon } from '@iconify/react';
// components
import { useLocation } from 'react-router-dom';
import {
  Upload
} from '../sections/@dashboard/app';

import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock

import { fShortenNumber } from '../utils/formatNumber';
import PersonInfoTable from './PersonTable';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'nachname', label: 'Name', alignRight: false },
  { id: 'vorname', label: 'Vorname', alignRight: false },
  { id: 'relation', label: 'Relation', alignRight: false },
  { id: 'beruf', label: 'Beruf', alignRight: false },
  { id: 'birthdate', label: 'Datum', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function parseDate(dateStr) {
  if (dateStr.length < 8) return new Date();
  const parts = dateStr.split(".");
  if (parts.length !== 3) return null;
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);
  return new Date(year, month, day);
}
function descendingComparator(a1, b1, orderBy) {
  let a = a1[orderBy];
  let b = b1[orderBy];
  if(orderBy==='birthdate'){
    a = parseDate(a)
    b = parseDate(b);
  }
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function PersonsPage() {
    const Fav = styled('fab')(({ theme }) => ({
      position: 'fixed',
      zIndex: 500,
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    }));
  const location = useLocation();

  const [USERLIST, setUserList] = useState([]);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(1);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('followers');

  const [filterName, setFilterName] = useState('');
  const [totalUsers, setTotalUsers] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [isMobile, setIsMobile] = useState(false);
  
  const [uploadResult, setUploadResult] = useState(null);
  
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    
    if (query && query.length > 0) {
      const filteredArray = array.filter((_user) => {
        const lowerCaseQuery = query.toLowerCase();
        const nameMatch = _user.name.toLowerCase().indexOf(lowerCaseQuery) !== -1;
        const tagsMatch = _user.relation.toLowerCase().indexOf(lowerCaseQuery) !== -1;
        return nameMatch || tagsMatch;
      });
      
      const stabilizedFiltered = filteredArray.map((el, index) => [el, array.indexOf(el)]);
      
      stabilizedFiltered.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      
      return stabilizedFiltered.map((el) => el[0]);
    }     
    stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
    });      
    return stabilizedThis.map((el) => el[0]);
  }
  

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 50); 
  };

  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value);
  };

  const extensionIcons = {
    png: { icon: 'file-image', color: '#e57373' },
    jpg: { icon: 'file-image', color: '#e57373' },
    jpeg: { icon: 'file-image', color: '#e57373' },
    gif: { icon: 'file-image', color: '#e57373' },
    bmp: { icon: 'file-image', color: '#e57373' },
    pdf: { icon: 'file-pdf', color: '#64b5f6' },
    docx: { icon: 'file-word', color: '#81c784' },
    ppt: { icon: 'file-powerpoint', color: '#ffab91' },
    pptx: { icon: 'file-powerpoint', color: '#ffab91' },
    xls: { icon: 'file-excel', color: '#4db6ac' },
    xlsx: { icon: 'file-excel', color: '#4db6ac' },
    mp3: { icon: 'file-music', color: '#9575cd' },
    wav: { icon: 'file-music', color: '#9575cd' },
    ogg: { icon: 'file-music', color: '#9575cd' },
    mp4: { icon: '', color: '#f06292' },
    avi: { icon: '', color: '#f06292' },
    mkv: { icon: '', color: '#f06292' },
    mov: { icon: '', color: '#f06292' },
    // Add more extensions and corresponding Iconify icons/colors as needed
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const [user, setUser] = useState(null);
  const [currentUser, setCUrrentUser] = useState({vorname: "", nachname: ""});
  const [currentUserIndex, setCUrrentUserIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    async function fetchImages() {
      const token = localStorage.getItem('token');
      const imagePromises = USERLIST.map(async (item) => {
        const path = `https://blue.the-centaurus.com/api/person/${item.nachname}_${item.vorname}/profile.jpeg`
        
        

        const response = await fetch(path, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      });

      try {
        const imageDataUrls = await Promise.all(imagePromises);
        setImageUrls(imageDataUrls);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    }

    fetchImages();
  }, [USERLIST]); 
  
  useEffect(() => {  
    setIsMobile(window.innerWidth <= 768);
    
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }
    fetch('https://blue.the-centaurus.com/api/user', {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      console.log(response)
      setUser(response.json().user);
    })
    .catch(error => {
      console.log(error);
      window.location.href = '/login';
    })
  }, []);
  
  async function fetchData(url) {
    const token = localStorage.getItem('token');
    console.log(token);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    
    let  data = await response.json();
    try {
      data = JSON.parse(data);    
    } catch (error) {
      console.log("err")
    }
  
    return data;
  }
  
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {  
    async function fetchUsers() {
      const  profiles  = await fetchData('https://blue.the-centaurus.com/api/get_persons');
      console.log(profiles)
      setTotalUsers(profiles.length)
      setUserList(profiles.map((item) => ({
        id: item.id,
        name: `${item.vorname} ${item.nachname}`,
        vorname: item.vorname,
        nachname: item.nachname,
        birthdate: item.geburtsdatum,
        todesdatum: item.todesdatum,
        relation: item.relation,
        mail: item.mail,
        sprachen: item.sprachen,
        telefon: item.telefon,
        beruf: item.beruf,
        adresse: item.adresse,
        wichtiges: item.wichtiges,
        lebenslauf: item.lebenslauf,
        studiengang: item.studiengang,
      })));
    }
    fetchUsers();
  }, []);
  
  const handleSnackbarClose = () => {
    setUploadResult(null);
  };


  const handleOpenDialog = (user, index) => {
    console.log(user)
    console.log(index)
    //  console.log(filteredUsers[user])
    setCUrrentUser(user)
    setCUrrentUserIndex(index)
    setDialogOpen(true);
  };

  
  const handleCloseDialog = () => {
      setDialogOpen(false);
  };
  return (
    <>
      <Helmet>
        <title> Persons | Blue </title>
      </Helmet>


      
      <Fav>
      <Fab color="secondary" aria-label="add">
          <Iconify  icon={'bi:plus'}  color="#fff" width={32} />
      </Fab>
      </Fav>
      <Container>
        
        <Card style={{background: '#222', color:'#ccc'}}>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, width: '100%' }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id, name, birthdate, todesdatum, extension,size, vorname, nachname, relation, mail,beruf, studiengang, telefon, sprachen, adresse, lebenslauf,  wichtiges} = row;
                    const selectedUser = selected.indexOf(name) !== -1;
                    console.log(extension)
                    const { icon, color } = extensionIcons[extension] || { icon: 'file', color: '#9e9e9e' }; 
                    const handleRowClick = () => {
                        handleOpenDialog(row, index); // Pass the entire row data to the function
                      };
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser} onClick={handleRowClick}>
                      
                        <TableCell component="th" scope="row" padding="none"  style={{ border: '1px solid #000' }}> 
                          <Stack direction="row" alignItems="center" spacing={2}>

                          <Avatar sx={{ position: 'relative', background: color, marginLeft: '10px'  }}>
                                  <img
                                    style={{ objectFit: 'cover', zIndex: 5, height:'100%' }}
                                    src={ imageUrls[index]} 
                                    alt=""
                                  />
                                  <span
                                    style={{
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      color: '#303030',
                                      transform: 'translate(-50%, -50%)',
                                      fontSize: '14px', 
                                    }}
                                  >
                                    {vorname && nachname ? `${vorname[0]}${nachname[0]} ` : ""}
                                  </span>
                                </Avatar>

                            <Typography variant="subtitle2" noWrap>
                              {nachname}
                            </Typography>
                          </Stack>
                        </TableCell>
                        
                        <TableCell  style={{ border: '1px solid #000' }} align="left">{vorname }</TableCell>
                        
                        <TableCell  style={{ border: '1px solid #000' }} align="left">{relation}</TableCell>
                        <TableCell  style={{ border: '1px solid #000' }} align="left">{beruf || studiengang}</TableCell>
                        <TableCell  style={{ border: '1px solid #000' }} align="left">{birthdate}</TableCell>
                        
                        
                       
                      </TableRow>
                    );
                  })}
                  
                </TableBody>

                {isNotFound && (
                  <TableBody style={{background: '#222'}}>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',background: '#222'
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          
          
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
            <Pagination
              color="primary"
              shape="rounded"
              count={Math.ceil(filteredUsers.length / rowsPerPage)}
              page={page}
              style={{ margin: '0 auto' }}
              onChange={handleChangePage}
            />
          </div>
            
        </Card>
      </Container>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
      <div style={{ minWidth:isMobile? undefined : 500, background: '#111'}}>
      <DialogContent style={{ width: isMobile? undefined : '100%', background: '#222', textAlign: 'center' }}>

      <Avatar sx={{ position: 'relative',  width: '120px',
            height: '120px',
            margin: '0 auto',
            marginBottom: '10px', }}>
                <img
                  style={{ objectFit: 'cover', zIndex: 5 }}
                  src={imageUrls[currentUserIndex]}
                  alt=""
                />
                <span
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    color: '#303030',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '33px', 
                  }}
                >
                  {currentUser.vorname && currentUser.nachname ? `${currentUser.vorname[0]}${currentUser.nachname[0]} ` : ""}
                </span>
              </Avatar>

      
        <DialogTitle>{`${currentUser.vorname} ${currentUser.nachname}`}</DialogTitle>
        <PersonInfoTable data={currentUser}/>
      </DialogContent>
      </div>
    </Dialog>

      <Snackbar open={uploadResult !== null} autoHideDuration={3000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={uploadResult === 'success' ? 'success' : 'error'}>
            {uploadResult === 'success' ? 'Files uploaded successfully!' : 'Failed to upload files.'}
          </Alert>
        </Snackbar>
    </>
  );
}