import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Typography, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';


// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [user, setUsername] = useState('');
  const [pswd, setPassword] = useState('');
  const [isLoginError, setIsLoginError] = useState(false);


  async function fetchData(url, requestBody) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    const data = await response.json();
    return data;
  }

  const handleLogin = async () => {
    try {
      const credentials  = {
        username: user,
        password: pswd,
      };

      const base64EncodedCredentials = btoa(`${credentials.username}:${credentials.password}`);
      const response = await fetch('https://blue.the-centaurus.com/api/login/', {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${base64EncodedCredentials}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      console.log(response)
      let isLoggedIn = false;
      const data  = (await response.json());
      const accessToken = data.access_token;
      console.log(data)
      console.log(accessToken)
      console.log(`Access Token: ${accessToken}`);
      
      if (response.status === 200 && accessToken!== undefined && accessToken!== null) {
        // Successful login
        isLoggedIn = true;
        localStorage.setItem('token', accessToken);
        window.location.href = '/dashboard';
        navigate('/dashboard', { replace: true });
      } else{
        console.log("error login in")
      }

      setIsLoginError(!isLoggedIn);

    } catch (error) {
      console.error(error);
    }
  };
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  
  return (
    <>
      <Stack spacing={3} 
          style={{color: '#eee'}}>
      <TextField name="username"  label="Username" value={user} onChange={handleUsernameChange} 
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            handleLogin();
          }
        }} 
      />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={pswd}
          onChange={handlePasswordChange}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleLogin();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
         {/* Conditionally render the error message */}
         {isLoginError && (
          <Typography color="error">Invalid username or password. Please try again.</Typography>
        )}
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" style={{marginTop: '10px'}} onClick={handleLogin}>
        Login
      </LoadingButton>
      </>
  );
}