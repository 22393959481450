import React, { useState, useEffect } from 'react';
import { Grid, Card, CardHeader,Tooltip, IconButton ,Pagination } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TablePagination from '@mui/material/TablePagination';
import {AppCurrentVisits} from '.';
import Iconify from '../../../components/iconify';

function MyImageGallery({ picturePaths,selectHighlightImg, imageDays, fShortenNumber,faceBoxes,showDecider,docsLength }) {
  const imagesPerPage = docsLength > 1 ? 50 : 51;
  const [currentPage, setCurrentPage] = useState(1);

  const [isMobile, setIsMobile] = useState(false);
  const [fetchFinished, setFetchFinished] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * imagesPerPage;
  const endIndex = Math.min(startIndex + imagesPerPage, picturePaths.length);

  // Get the subset of images to display for the current page
  let visibleImages = picturePaths.slice(startIndex, endIndex);
  if (!showDecider  && picturePaths.length === 1 && !selectHighlightImg){
    visibleImages = [];
  } 
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 50); // Add a slight delay before scrolling to the top
  };
 
  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };



  // Reset pagination to the first page and scroll to the top when picturePaths changes
  useEffect(() => {
    setCurrentPage(1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [picturePaths]);

  // Fetch images with authorization when the component mounts
  useEffect(() => {
    async function fetchImages() {
      setFetchFinished(false)
      const token = localStorage.getItem('token');
      const imagePromises = visibleImages.map(async (item) => {
        let path = "";
        if (item.path){
          if (item.path.includes('.mp4') || item.path.includes('.MP4') || item.path.includes('.AVI') || item.path.includes('.avi') || item.path.includes('.MOV') || item.path.includes('.WMV')){
            path = `https://blue.the-centaurus.com/api/images/${item.date}/compressed/${item.path.split("/").pop().replace(/\.[^.]+$/, '')}.jpg`;
          }else
            path = `https://blue.the-centaurus.com/api/images/${item.date}/compressed/${item.path.split("/").pop()}`;
        }else{
          path = `https://blue.the-centaurus.com/api/images/${item.date}/compressed/${item.highlight_image}`
        }
        

        const response = await fetch(path, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });
        const arrayBuffer = await response.arrayBuffer(); // Get the response as an ArrayBuffer
        const mimeType = response.headers.get('content-type'); // Get the MIME type from the response header
        const blobOptions = { type: mimeType, endings: 'native', lastModified: new Date() };
        const blob = new Blob([arrayBuffer], blobOptions);

        // Set a specific filename for the Blob
        const filename = 'test.jpg';

        // Create the object URL with the Blob and set a suggested filename
        const imageUrl = URL.createObjectURL(blob, { type: mimeType, endings: 'native', filename });
        console.log(imageUrl)
        return imageUrl;
      });

      try {
        const imageDataUrls = await Promise.all(imagePromises);
        setImageUrls(imageDataUrls);
        console.log("imageDataUrls")
        console.log(imageDataUrls)
      } catch (error) {
        console.error('Error fetching images:', error);
      }
      setFetchFinished(true)
    }

    fetchImages();
  }, [picturePaths, currentPage]); 
  
  // Check if pagination is necessary
  const showPagination = picturePaths.length > imagesPerPage;

  return (
    <>
      {imageDays === "runs" ? (
    <AppCurrentVisits
              data={ visibleImages}
              fShortenNumber = {fShortenNumber}
            />) : 
      (visibleImages.map((item, index) => (          
          <Grid item xs={12} md={6} lg={4} key={index}>
            <a 
              href={
                selectHighlightImg!== undefined 
                ? null : 
                (item.path === undefined
                  ? `/dashboard/day#${item.date}`
                  : `/dashboard/image#${item.path.replace('./Blue/stories_images/', '')}`)
              }
              rel="noreferrer"
              target= {selectHighlightImg!== undefined 
                ? null : (isMobile ? undefined : "_blank")}
              style={{textDecoration: 'none'}}
              onClick={(e) => {
                if(selectHighlightImg!== undefined){
                  selectHighlightImg(item.path); 
                }                          
              }}
              >
               <Tooltip title=              
                {item.path !== undefined && showDecider && (<div style={{ textAlign: 'center'}}>
                  <div>{item.date}</div>
                  <div style={{ fontSize: '14px', color: '#888' }}>{item.place}</div>
                  
                </div>)}
                 subheader={item.place} placement="top" enterDelay={500} arrow>
              <Card
                sx={{
                  height: '330px',
                  width: '100%',
                  background: '#111',
                  color: '#eee',
                }}
              >
                {item.path === undefined && (
                  <CardHeader
                    title={item.date}
                    subheader={item.place}
                    style={{ marginBottom: '10px' }}
                  />
                )}
                {item.path && (item.path.toLowerCase().includes('.mp4') || item.path.toLowerCase().includes('.wmv') || item.path.toLowerCase().includes('.avi') || item.path.toLowerCase().includes('.mov')) ? (<>
             <a href={`https://blue.the-centaurus.com/dashboard/day#${item.date}`}  style={{textDecoration: 'none', color:'#fff'}} target= {isMobile ? undefined : "_blank"} rel="noreferrer"><p style={{position: 'absolute', zIndex: 100, backdropFilter: 'blur(10px)', padding: '10px', background: 'rgba(0, 0, 0, 0.53)'}}>
                {`${item.date}`}</p></a>
             <p style={{position: 'absolute', right:'0px', bottom:'-17px', zIndex: 100, backdropFilter: 'blur(10px)', padding: '10px', background: 'rgba(0, 0, 0, 0.53)'}}>
                {`${formatTime(parseInt(item.image_tags.split(';')[0],10))}`}</p> 
            
           {item.place && <p style={{position: 'absolute', left:'0px', bottom:'-17px', zIndex: 100, backdropFilter: 'blur(10px)', padding: '10px', background: 'rgba(0, 0, 0, 0.53)'}}>
                {`${item.place}`}</p>}
            <p style={{position: 'absolute', right:'0px', zIndex: 100, backdropFilter: 'blur(10px)', padding: '10px', background: 'rgba(0, 0, 0, 0.53)'}}>
                {`${item.path.split("/").pop().slice(0, 15)}`}</p>

              <a href={`https://blue.the-centaurus.com/api/images/${item.date}/${item.path.split("/").pop()}`} target= {isMobile ? undefined : "_blank"} rel="noreferrer" >
            <LazyLoadImage
            src={ imageUrls[index] }
            alt={item.path}
            width="100%"
            height="100%"
            loading="lazy" 
            style={{ width: '100%', height: '100%', objectFit: 'cover' , filter: !fetchFinished ?'blur(5px)': "", transition: 'filter 0.3s ease-in-out'}}
            effect="blur"
          /></a></>): (<LazyLoadImage
            src={ imageUrls[index] }
            alt={item.date}
            width="100%"
            loading="lazy" 
            height="100%"
            style={{ width: '100%', height: '100%', objectFit: 'cover', filter: !fetchFinished ?'blur(5px)': "" , transition: 'filter 0.3s ease-in-out'}}
            effect="blur"
          />)}
                
              </Card>
            
          
            </Tooltip>
            </a>
          </Grid>
        )))}

      {showPagination && (
        <>
          <Grid container sx={{ width: '100%', justifyContent: 'center', marginTop: '10px' }}>
          <Pagination
              color="primary"
              shape="rounded"
              count={Math.ceil(picturePaths.length / imagesPerPage)}
              page={currentPage}
              onChange={handleChangePage}
            />
          </Grid>
        </>
      )}
    </>
  );
}

export default MyImageGallery;